<script setup lang="ts">
import { OrganismReviewsProps } from "~/components/ProductPage/Reviews/OrganismReviews.props"
import { ProductDetailsProps } from "./ProductDetails.props"
import {
  mapBrands,
  mapProductsAlgolia,
  ProductAlgolia
} from "~/utils/mapProducts"
import { algoliaSearch } from "~/config/algolia"
import {
  PRODUCT_VIEWED,
  VIEW_SAME_CATEGORY_PDP,
  VIEW_ACTIVE_PRINCIPLE_PDP,
  FREQUENTLY_BOUGHT_TOGETHER
} from "~/config/algolia-insights"
import { insightsAlgoliaMiddleware } from "~/utils/insightsAlgoliaMiddleware"
import { viewProductDetail } from "~/utils/algolia"
import { ContainerProps } from "~/components/Banner/ProductPage/Support/Container.props"
import type { WithContext, Drug, Product } from "schema-dts"
import { FastShippingProps } from "~/components/Banner/Delivery/FastShipping.props"
import { DefaultProps } from "~/components/ProductPage/Banner/Default.props"
import {
  AisInstantSearch,
  AisConfigure,
  AisHits,
  AisIndex
  // @ts-ignore
} from "vue-instantsearch/vue3/es/index.js"
import { pricePerUnit } from "~/utils/formatters"
import { checkEnvSite } from "~/server/utils/getAlgoliaConfig"
import { productUtils } from "~/utils/product"
import { excludeOutOfMarketRuleContext } from "~/utils/algolia"
import { cleanJsonLdObject } from "~/utils/jsonLd"
import { availabilityLeadEvent } from "~/utils/analytics/availabilityLead"

const props = defineProps<ProductDetailsProps>()
const emit =
  defineEmits<{
    (e: "onPurchase", value: number): void
    (e: "onSaveForLater"): void
  }>()

const isLoading = ref(true)

// As per TF-2292, making the "description" a ref so whenever the "correspondingGenericDrug" values are available, re-render it
const newDescription = ref(props.description)

const reviewsProduct = ref<HTMLElement | null>(null)
const alternativeProducts = ref<HTMLElement | null>(null)
const positionTopReview = computed(() =>
  reviewsProduct.value ? useElementBounding(reviewsProduct).top : undefined
)
const positionTopAlternativeProducts = computed(() =>
  alternativeProducts.value
    ? useElementBounding(alternativeProducts).top
    : undefined
)

const isLogged = useIsLogged()

const skuState = useState("product-unavailable-state")
const { inStockSubscriptionItem, sendSubscription } = useInStockSubscription()

const insightsMiddlewareSameCategory = insightsAlgoliaMiddleware(
  VIEW_SAME_CATEGORY_PDP
)
const insightsMiddlewareActivePrinciple = insightsAlgoliaMiddleware(
  VIEW_ACTIVE_PRINCIPLE_PDP
)
const insightsMiddlewareFrequentlyBoughtTogether = insightsAlgoliaMiddleware(
  FREQUENTLY_BOUGHT_TOGETHER
)
const { t } = useI18n()
const { $cl } = useNuxtApp()

const supportCardsData = computed<ContainerProps>(() => {
  return {
    infoCard1: {
      iconType: "call",
      title: t("supportBanner.cards.helpdesk.title"),
      text: t("supportBanner.cards.helpdesk.text"),
      link: t("supportBanner.cards.helpdesk.link"),
      linkText: t("supportBanner.cards.helpdesk.linkText"),
      sku: props.details?.productId
    },
    infoCard2: {
      iconType: "mail",
      title: t("supportBanner.cards.callPharma.title"),
      text: t("supportBanner.cards.callPharma.text"),
      link: t("supportBanner.cards.callPharma.link"),
      linkQuery: t("supportBanner.cards.callPharma.linkQuery", {
        param: helpSubjectParams.consulting_pharma
      }),
      linkText: t("supportBanner.cards.callPharma.linkText"),
      sku: props.details?.productId
    }
  }
})

const bannerDefaultData = computed<DefaultProps>(() => {
  return {
    banners: [
      {
        title: t("productPage.banners.default.safePayments"),
        iconType: "award"
      },
      {
        title: t("productPage.banners.default.freeShipping"),
        iconType: "car"
      },
      {
        title: t("productPage.banners.default.orderTracking"),
        iconType: "box"
      },
      {
        title: t("productPage.banners.default.subscriberOffers"),
        iconType: "pricetag"
      },
      {
        title: t("productPage.banners.default.quickReturns"),
        iconType: "return"
      }
    ],
    bgImageLarge: getImageData({
      altText: "Placeholder",
      url: "/i5oh5hzamkwo/m8mOjBmE8iHbnJM1y6CYY/d8e244e5a3c52e562765764c5c8ff73a/background2.svg",
      width: 1222,
      height: 154
    })
  }
})

const bannerFastShippingData: FastShippingProps = {
  theme: "yellow",
  isTiny: false,
  isGreenBold: false,
  title: t("productPage.banners.fastShipping.title"),
  text: t("productPage.banners.fastShipping.text")
}

interface SingleReview {
  merchantReview: string
  reviewerName: string
  rating: number
  date: Date | null
  textReview: string
}

interface TypeRatingData {
  ratings: number
  stars: number
  reviewsByStars: SingleReviewsByStars[]
  productReviews: SingleReview[]
}

interface SingleReviewsByStars {
  reviewNumber: number
}

const starsRatings = ref(0)
const valueRatings = ref(0)
const totalRatings = ref()
const reviewsRatings = ref([])
const searchBarText = ref("")

const mainCategorySlug = computed(() => {
  const lengthLinks = props.breadcrumbs?.links?.length
  const lastLink = props.breadcrumbs?.links?.[lengthLinks - 1]

  if (lastLink && typeof lastLink.to === "string") {
    return lastLink.to.slice(3)
  } else {
    return ""
  }
})

const {
  data: getTotalReviewsAverages,
  error: errorTotalReviewsAverages,
  pending: pendingTotalReviewsAverages
} = useLazyFetch(`/api/feedaty/getReviewsAverages/${props.details.productId}`, {
  server: false
})

if (errorTotalReviewsAverages.value) {
  console.warn("error access token", errorTotalReviewsAverages.value)
}

watch(getTotalReviewsAverages, () => {
  if (getTotalReviewsAverages.value) {
    valueRatings.value =
      getTotalReviewsAverages.value?.Data?.Products?.RatingsCount ?? 0
    starsRatings.value =
      getTotalReviewsAverages.value?.Data?.Products?.AverageValue ?? 0
  }
})

const {
  data: getTotalReviews,
  error: errorGetTotalReviews,
  pending: loadingReviews
} = useLazyFetch(`/api/feedaty/getReviews/${props.details.productId}`, {
  server: false
})

const reviews = computed((): OrganismReviewsProps => {
  const search = searchBarText.value.toUpperCase()
  const filterReviews =
    search !== ""
      ? reviewsRatings.value.filter((singleReview: SingleReview) => {
          const textReview = singleReview.textReview.toUpperCase()
          const merchantReview = singleReview.merchantReview.toUpperCase()
          return textReview.includes(search) || merchantReview.includes(search)
        })
      : reviewsRatings.value

  return {
    productName: props.details.title,
    isProductDrug: (newDescription?.value?.descriptions?.length ?? 0) > 1, // Drugs have more than one description
    reviewNumber: valueRatings.value,
    reviewAverage: starsRatings?.value?.toString() ?? "0",
    dropdownMenu: [{ label: t("productPage.organismReviews.bestReviews") }],
    reviewsByStars: totalRatings.value,
    searchSettings: {
      name: "search-reviews",
      label: "",
      placeholder: t("productPage.organismReviews.findKeyword"),
      modelValue: searchBarText.value,
      doNotOpenPopup: true
    },
    productReviews: filterReviews
  }
})

watch(getTotalReviews, () => {
  const reviews = getTotalReviews.value?.Data?.Reviews ?? []
  const newMapReviews = reviews.map((singleReview: SingleReview) => {
    const productReview = singleReview?.ProductsReviews?.[0]
    return {
      merchantReview: singleReview?.MerchantReview ?? "",
      reviewerName: singleReview?.CustomerName ?? "",
      rating: productReview?.Rating ?? 0,
      date: null,
      textReview: `“${productReview?.Review}”`
    }
  })

  const countByRating = (rating: number) =>
    newMapReviews.filter((singleRating) => singleRating.rating === rating)
      .length

  const totalReviews = [
    { reviewNumber: countByRating(5) },
    { reviewNumber: countByRating(4) },
    { reviewNumber: countByRating(3) },
    { reviewNumber: countByRating(2) },
    { reviewNumber: countByRating(1) }
  ]

  const sortedReverseReviews = newMapReviews.sort(
    (a: { rating: number }, b: { rating: number }) => b.rating - a.rating
  )

  totalRatings.value = totalReviews
  reviewsRatings.value = sortedReverseReviews
})

if (errorGetTotalReviews.value) {
  console.warn("error access token", errorGetTotalReviews.value)
}

const scrollToReviews = () => {
  const header = document.querySelector("header")
  const headerHeight = header ? header.offsetHeight + 50 : 0
  if (typeof positionTopReview.value === "number") {
    window.scrollTo({
      top: positionTopReview.value - headerHeight,
      behavior: "smooth"
    })
  }
}

const scrollToAlternativeProducts = () => {
  const header = document.querySelector("header")
  const headerHeight = header ? header.offsetHeight + 50 : 0
  if (typeof positionTopAlternativeProducts.value === "number") {
    window.scrollTo({
      top: positionTopAlternativeProducts.value - headerHeight,
      behavior: "smooth"
    })
  } else if (
    positionTopAlternativeProducts.value?.value &&
    typeof positionTopAlternativeProducts.value.value === "number"
  ) {
    window.scrollTo({
      top: positionTopAlternativeProducts.value.value - headerHeight,
      behavior: "smooth"
    })
  }
}

const breadcrumbs = computed(() => {
  return {
    ...props.breadcrumbs,
    hideFirstItemInMobile: true,
    links: [{ label: "Home", to: "/" }, ...props.breadcrumbs?.links]
  }
})

const searchClient = useAlgoliaRef()

const checkLastViewedProductsWithCookies = () => {
  const lastViewedProducts = useStatefulCookie("lastProductsViewed", {
    default: () => []
  })

  if (lastViewedProducts.value) {
    const productId = props.details.productId
    lastViewedProducts.value = (productId ? [productId] : []).concat(
      lastViewedProducts.value.filter((i) => i !== productId).slice(0, 10)
    )
  } else {
    lastViewedProducts.value = [props.details.productId]
  }
}

const brandBannerSupport = (product: ProductAlgolia) => {
  if (props?.brand?.brandBanner?.brandTitle) {
    return {
      ...props.brand.brandBanner,
      card: product
    }
  } else {
    return undefined
  }
}

const {
  data: boughtTogetherProductsResults,
  pending: pendingBoughtTogetherProducts,
  error: errorBoughtTogetherProducts
} = useLazyFetch(
  `/api/algolia/getRecommendations/recommend-2/${props.details.productId}`,
  {
    server: false
  }
)

onMounted(async () => {
  try {
    skuState.value = props.details.productId

    checkLastViewedProductsWithCookies()

    const algolia = await algoliaSearch()
    viewProductDetail(algolia.indices.products, `${PRODUCT_VIEWED}`, [
      `product_${props.details.productId}`
    ])
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
})

onNuxtReady(async () => {
  if (props.scrollToAlternativeProducts) {
    scrollToAlternativeProducts()
  }
})

const boughtTogetherProducts = computed(() => {
  const products = boughtTogetherProductsResults.value?.results[0]?.hits ?? []
  return mapProductsAlgolia(products)
})

const { mapBannersSection: bannersSection } = useMapCart()

/** get event event_select_item_{productCode} */
const { event: productAvailableWarning } = useGAEvent(
  `track_product_availability_warning_${props.details?.productId ?? ""}_`,
  "custom_event"
)
/** Define single GA4 item */
const GAItem = computed((): GA4Entities["gaItem"] => {
  if (Object.keys(productAvailableWarning.value).length) {
    return productAvailableWarning.value?.items?.[0] ?? {}
  } else {
    return {
      index: 0,
      item_id: props.details?.productId ?? "",
      item_name: props.details?.title ?? "",
      item_list_name: "",
      item_brand: props.brand?.brandName ?? "",
      price: parseFloat(props.details?.price?.toString()) ?? "",
      discount:
        props.details?.oldPrice && props.details?.price
          ? props.details.oldPrice - props.details.price
          : "",
      item_category: props.breadcrumbs?.links[0]?.label ?? "",
      item_category2: props.breadcrumbs?.links[1]?.label ?? "",
      item_category3: props.breadcrumbs?.links[2]?.label ?? "",
      item_category4: props.breadcrumbs?.links[3]?.label ?? "",
      item_category5: props.breadcrumbs?.links[4]?.label ?? "",
      available: props.details.stockQuantity ? "si" : "no",
      quantity: props.details.stockQuantity ?? 0,
      bundle_name: "",
      currency: "EUR"
    }
  }
})

/**
 * @description Send event when the user sends a notification request
 */
const sendAvailabilityLeadEvent = async () => {
  const cat =
    props.breadcrumbs?.links[props.breadcrumbs?.links.length - 1].label

  inStockSubscriptionItem.value = {
    event: availabilityLeadEvent.availabilityLead,
    category: cat,
    action: props.brand?.brandName ?? "",
    label: props.details?.title ?? ""
  }

  await sendSubscription()
}

if (process.server) {
  const baseUrl = useRuntimeConfig().public.BASE_URL_APP
  const jsonLd: ComputedRef<WithContext<Drug | Product>> = computed(() =>
    // Drugs have more than one description
    (newDescription?.value?.descriptions?.length ?? 0) > 1
      ? {
          "@context": "https://schema.org",
          "@type": "Drug",
          name: props.details.title,
          description: props.details.description,
          image: props.details.gallery.map((el) => el?.image?.url),
          mpn: props.details.productId,
          sku: props.details.productId,
          url: `${baseUrl}/${useRoute().fullPath}`,
          activeIngredient: props.activeSubstance,
          administrationRoute: newDescription.value.infos.howToUse,
          ...(props.activeSubstance
            ? {
                availableStrength: {
                  "@type": "DrugStrength",
                  activeIngredient: props.activeSubstance
                }
              }
            : {}),
          dosageForm: props.details.unitType,
          ...(props.activeSubstance
            ? {
                drugClass: {
                  "@type": "DrugClass",
                  name: props.activeSubstance
                }
              }
            : {}),
          drugUnit: newDescription.value.infos.dosageType,
          legalStatus: {
            "@type": "DrugPrescriptionStatus",
            applicableLocation: "IT",
            name: "OTC"
          },
          category: newDescription.value.infos.category,
          offers: {
            "@type": "Offer",
            price: props.details.price,
            priceCurrency: "EUR",
            url: `${baseUrl}/${useRoute().fullPath}`,
            itemCondition: "http://schema.org/NewCondition",
            availability:
              props.details.stockQuantity === 0
                ? "https://schema.org/SoldOut"
                : "https://schema.org/InStock",
            shippingDetails: {
              "@type": "OfferShippingDetails",
              shippingRate: {
                "@type": "MonetaryAmount",
                value: props.details.price > 19.9 ? 0 : 4.9,
                currency: "EUR"
              },
              shippingDestination: {
                "@type": "DefinedRegion",
                addressCountry: "IT",
                postalCodeRange: {
                  postalCodeBegin: "00010",
                  postalCodeEnd: "97100"
                }
              },
              deliveryTime: {
                "@type": "ShippingDeliveryTime",
                handlingTime: {
                  "@type": "QuantitativeValue",
                  minValue: "0",
                  maxValue: "1"
                },
                transitTime: {
                  "@type": "QuantitativeValue",
                  minValue: "1",
                  maxValue: "4"
                },
                cutOffTime: "18:30-08:00",
                businessDays: {
                  "@type": "OpeningHoursSpecification",
                  dayOfWeek: [
                    "https://schema.org/Monday",
                    "https://schema.org/Tuesday",
                    "https://schema.org/Wednesday",
                    "https://schema.org/Thursday",
                    "https://schema.org/Friday"
                  ]
                }
              }
            },
            seller: {
              "@type": "Organization",
              url: "https://www.topfarmacia.it/",
              name: "Top Farmacia",
              "@id": "#store-organization",
              logo: `${baseUrl}/images/LogoTopFarmaciaOnline.svg`
            }
          },
          ...(!!props.brand
            ? {
                brand: {
                  "@type": "Brand",
                  name: props.brand?.brandName,
                  logo: props.brand?.brandBanner?.brandImage?.image?.url
                }
              }
            : {}),
          ...(props.reviews?.aggregateRating?.reviewCount &&
          props.reviews.aggregateRating.reviewCount !== "0"
            ? {
                aggregateRating: props.reviews?.aggregateRating
              }
            : {}),
          ...((props.reviews?.review?.length ?? 0) > 0
            ? {
                review: props.reviews?.review
              }
            : {})
        }
      : {
          "@context": "https://schema.org",
          "@type": "Product",
          name: props.details.title,
          description: props.details.description,
          image: props.details.gallery.map((el) => el?.image?.url),
          mpn: props.details.productId,
          sku: props.details.productId,
          url: `${baseUrl}/${useRoute().fullPath}`,
          category: newDescription.value.infos.category,
          offers: {
            "@type": "Offer",
            price: props.details.price,
            priceCurrency: "EUR",
            url: `${baseUrl}/${useRoute().fullPath}`,
            priceValidUntil: "2024-04-22",
            itemCondition: "http://schema.org/NewCondition",
            availability:
              props.details.stockQuantity === 0
                ? "https://schema.org/SoldOut"
                : "https://schema.org/InStock",
            shippingDetails: {
              "@type": "OfferShippingDetails",
              shippingRate: {
                "@type": "MonetaryAmount",
                value: props.details.price > 19.9 ? 0 : 4.9,
                currency: "EUR"
              },
              shippingDestination: {
                "@type": "DefinedRegion",
                addressCountry: "IT",
                postalCodeRange: {
                  postalCodeBegin: "00010",
                  postalCodeEnd: "97100"
                }
              },
              deliveryTime: {
                "@type": "ShippingDeliveryTime",
                handlingTime: {
                  "@type": "QuantitativeValue",
                  minValue: "0",
                  maxValue: "1"
                },
                transitTime: {
                  "@type": "QuantitativeValue",
                  minValue: "1",
                  maxValue: "4"
                },
                cutOffTime: "18:30-08:00",
                businessDays: {
                  "@type": "OpeningHoursSpecification",
                  dayOfWeek: [
                    "https://schema.org/Monday",
                    "https://schema.org/Tuesday",
                    "https://schema.org/Wednesday",
                    "https://schema.org/Thursday",
                    "https://schema.org/Friday"
                  ]
                }
              }
            },
            seller: {
              "@type": "Organization",
              url: "https://www.topfarmacia.it/",
              name: "Top Farmacia",
              "@id": "#store-organization",
              logo: `${baseUrl}/images/LogoTopFarmaciaOnline.svg`
            }
          },
          ...(!!props.brand
            ? {
                brand: {
                  "@type": "Brand",
                  name: props.brand?.brandName,
                  logo: props.brand?.brandBanner?.brandImage?.image?.url
                }
              }
            : {}),
          ...(props.reviews?.aggregateRating?.reviewCount &&
          props.reviews.aggregateRating.reviewCount !== "0"
            ? {
                aggregateRating: props.reviews?.aggregateRating
              }
            : {}),
          ...((props.reviews?.review?.length ?? 0) > 0
            ? {
                review: props.reviews?.review
              }
            : {})
        }
  )

  useHead({
    script: [
      {
        type: "application/ld+json",
        children: JSON.stringify(cleanJsonLdObject(jsonLd.value))
      }
    ]
  })
}

const genericDrugFilter = `[["correspondingEquivalentDrugs:${props.details.productId}"],["genericDrug:true"]]`

const mapProductDescription = (items: any[]) => {
  // As per TF-2292, adding a check to avoid the "Cannot read properties of undefined" Sentry error
  const { productName, slug } = items?.[0] || {}
  if (productName && slug) {
    newDescription.value.infos.correspondingGenericDrug = {
      productName,
      slug: "/p-" + slug
    }
  }

  return items
}

const searchFunction = (helper: any) => {
  helper.setQueryParameter("clickAnalytics", true).search()
}
</script>

<template>
  <div>
    <UtilsBreadcrumbs v-if="breadcrumbs" v-bind="breadcrumbs" />

    <div class="grid-container padded relative">
      <!-- TODO: SHAPE TO ANIMATE -->
      <UtilsIcon
        name="Shape.svg"
        class="absolute left-0 top-[500px] -z-10 hidden md:block"
      />
      <div class="grid-container col-span-full my-3 md:my-5">
        <div class="col-span-full md:col-span-8">
          <ProductPageDetail
            v-bind="details"
            :expirationDate="details.expirationDate"
            :sellabilityStatus="sellabilityStatus"
            :product-id="details.productId"
            :ratings="valueRatings"
            :stars="Math.round(starsRatings)"
            :pending-ratings="pendingTotalReviewsAverages"
            @on-reviews="scrollToReviews"
          />
        </div>
        <div class="col-span-full md:col-span-4">
          <ProductPagePurchaseTypeSingle
            v-if="details.stockQuantity"
            class="bg-white"
            :class="{ hidden: details.price === 0 }"
            :price="details.price"
            :price-per-unit="pricePerUnit(details.price, details.quantity)"
            :unit-type="details.unitType"
            :stock-quantity="details.stockQuantity"
            :product="{
              details,
              description,
              gaItem: GAItem
            }"
            @onPurchase="emit('onPurchase', $event)"
            @onSaveForLater="emit('onSaveForLater')"
          />
          <ProductPagePurchaseTypeNotAvailable
            v-else-if="productUtils.isSellableStatus(sellabilityStatus)"
            :product="{
              details,
              description,
              gaItem: GAItem
            }"
            @handle-notify-me="sendAvailabilityLeadEvent"
          />
          <ProductPagePurchaseTypeNotSellable
            v-else
            :product-code="details?.productId"
            @handle-scroll-to-alternatives="scrollToAlternativeProducts"
          />
        </div>

        <BannerFirstTimeHere v-if="!isLogged" />
      </div>

      <div
        v-for="promotion in promotionsGift"
        :key="promotion.giftText"
        class="col-span-full my-3 md:my-0"
      >
        <BannerProductPageGift v-if="promotion.giftText" v-bind="promotion" />
      </div>

      <div class="grid-container col-span-full my-3 md:my-5">
        <ProductPageDescription
          class="col-span-full md:col-span-8"
          v-bind="newDescription"
          :has-faqs="faq.questions.length !== 0"
        />
        <div class="col-span-full md:col-span-4">
          <BannerDeliveryFastShipping v-bind="bannerFastShippingData" />
          <BannerProductPageSupportContainer v-bind="supportCardsData" />
        </div>
      </div>

      <div class="grid-container col-span-full my-3 md:my-5">
        <div class="col-span-full mb-3 md:col-span-8 md:mb-0 md:h-full">
          <!-- BUNDLE -->
          <ProductPageBundle
            v-if="
              boughtTogetherProducts?.length &&
              !pendingBoughtTogetherProducts &&
              !errorBoughtTogetherProducts
            "
            :products="boughtTogetherProducts"
          />
        </div>
        <ProductPageBannerDefault
          v-bind="bannerDefaultData"
          class="col-span-full"
          :class="{ 'md:col-span-4': boughtTogetherProducts?.length }"
          :is-long-version="!boughtTogetherProducts?.length"
        />
      </div>
    </div>
    <a ref="alternativeProducts" id="alternativeProducts"></a>
    <NuxtLazyHydrate>
      <AisInstantSearch
        :search-client="searchClient"
        :search-function="searchFunction"
      >
        <!-- Similar generic products -->
        <AisIndex
          :index-name="`TOP_PRODUCTS${checkEnvSite}`"
          index-id="similar-generic-products"
        >
          <AisConfigure
            :hits-per-page.camel="15"
            :distinct="true"
            :facet-filters.camel="genericDrugFilter"
            :ruleContexts="excludeOutOfMarketRuleContext"
          />
          <AisHits :transform-items="mapProductDescription">
            <template #default="{ items }">
              <ProductPageGenericProduct
                v-if="items.length"
                :title="genericDrugsTitle"
                :description="genericDrugsDescription"
                :products="mapProducts(items, null, 'ALGOLIA')"
              />
              <div v-else></div>
            </template>
          </AisHits>
        </AisIndex>

        <!-- Same category products -->
        <AisIndex
          :index-name="`TOP_PRODUCTS${checkEnvSite}`"
          index-id="same-category-products"
          :middlewares="[insightsMiddlewareSameCategory]"
          :v-if="mainCategorySlug"
        >
          <AisConfigure
            :hits-per-page.camel="15"
            :distinct="true"
            :facet-filters.camel="`mainCategory.slug:${mainCategorySlug}`"
          />
          <AisHits :transform-items="mapProductsAlgolia">
            <template #default="{ items }">
              <RailsProduct
                v-if="items.length"
                :suggested-cards="items"
                class="my-3 md:my-5"
                :title="$t('productDetails.sameCategory')"
                type-card="Product"
                :skip="details.productId"
              />
              <div v-else></div>
            </template>
          </AisHits>
        </AisIndex>

        <!-- Same active principle -->
        <AisIndex
          v-if="activeSubstance"
          :index-name="`TOP_PRODUCTS${checkEnvSite}`"
          index-id="same-active-priciple-products"
          :middlewares="[insightsMiddlewareActivePrinciple]"
        >
          <AisConfigure
            :hits-per-page.camel="15"
            :distinct="true"
            :facet-filters.camel="`activeSubstance:${activeSubstance}`"
          />
          <AisHits :transform-items="mapProductsAlgolia">
            <template #default="{ items }">
              <RailsProduct
                v-if="items.length"
                :suggested-cards="items"
                class="my-3 md:my-5"
                :title="$t('productDetails.sameActiveSubstance')"
                type-card="Product"
                background-color="tiffany"
              />
              <div v-else></div>
            </template>
          </AisHits>
        </AisIndex>

        <div class="relative pb-3 md:pb-6">
          <div class="grid-container padded">
            <ProductPageReviewsQuestionForYou
              :question="
                $t('productPage.questionForYou.howRelevantSuggestedProducts')
              "
              :resource="details?.slug"
              class="
                col-span-full col-start-1
                my-3
                md:col-span-8 md:col-start-3
                md:my-5
              "
            />
          </div>

          <!-- FAQs -->

          <AisIndex
            :index-name="`TOP_PRODUCTS${checkEnvSite}`"
            index-id="faqs"
            v-if="faq?.questions?.length"
          >
            <AisConfigure
              :hits-per-page.camel="1"
              :distinct="true"
              :facet-filters.camel="`brand.name:${brand.brandName}`"
            />
            <AisHits :transform-items="mapProductsAlgolia">
              <template #default="{ items }">
                <ProductPageSupport
                  v-if="faq?.questions?.length"
                  id="supporto"
                  class="padded my-10 md:my-16"
                  v-bind="faq"
                  :banner="brandBannerSupport(items[0])"
                />
                <div v-else></div>
              </template>
            </AisHits>
          </AisIndex>

          <!-- Reviews -->
          <div
            id="container-reviews"
            class="grid-container padded py-3 md:py-5"
            v-if="valueRatings > 0"
          >
            <ProductPageReviewsOrganismReviews
              v-if="reviews && !loadingReviews"
              v-bind="reviews"
              ref="reviewsProduct"
              class="col-span-full mb-10 md:mb-20"
              @on-search="(newValue) => (searchBarText = newValue)"
            />
            <SkeletonReviews v-else class="col-span-full mb-10 md:mb-20" />
          </div>

          <OrganismsCartBannerDeliveryPayments
            class="md:padded"
            v-if="bannersSection"
            v-bind="bannersSection"
          />

          <!-- Suggested brands -->
          <!-- Hide top brends [jira task] TF-2719 -->
          <AisIndex v-if="false" :index-name="`TOP_BRANDS`">
            <AisConfigure
              :hits-per-page.camel="10"
              :distinct="true"
              :facet-filters.camel="`brandName:${brand.brandName}`"
            />
            <AisHits :transform-items="mapBrands">
              <template #default="{ items }">
                <RailsSuggestedBrands
                  v-if="items?.length"
                  :title="$t('productDetails.suggestedBrands')"
                  :suggested-cards="items"
                  bg-color="bg-grey-main"
                />
                <div v-else></div>
              </template>
            </AisHits>
          </AisIndex>

          <BannerNewsletter class="padded" />
          <!-- TODO: shape to animate -->

          <div
            class="
              absolute
              left-0
              top-0
              -z-10
              hidden
              max-h-full
              overflow-hidden
              md:block
            "
          >
            <IconsShape />
          </div>
        </div>
      </AisInstantSearch>
    </NuxtLazyHydrate>
  </div>
</template>
